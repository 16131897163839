<template>
  <div class="accordion" v-bind:class="theme">
    <div class="header" v-on:click="toggle">
      <slot name="header"></slot>
      <span
        class="lnr lnr-chevron-down header-icon"
        :class="{ rotate: show }"
      ></span>
    </div>
    <transition
      name="accordion"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:before-leave="beforeLeave"
      v-on:leave="leave"
    >
      <div class="body" v-show="show">
        <div class="body-inner">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* https://codepen.io/ktsn/pen/dzNRjx */
export default {
  name: "accordion",
  props: ["theme", "state"],
  data() {
    return {
      show: this.state == "expand",
    };
  },
  methods: {
    toggle: function() {
      this.show = !this.show;
    },
    // enter: function(el, done) {
    //   $(el).slideDown(150, done);
    // },
    // leave: function(el, done) {
    //   $(el).slideUp(150, done);
    // },
    beforeEnter: function(el) {
      el.style.height = "0";
    },
    enter: function(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave: function(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave: function(el) {
      el.style.height = "0";
    },
  },
};
</script>
<style scoped>
/* https://codepen.io/ktsn/pen/dzNRjx */
.accordion {
  margin-bottom: 20px;
}

.accordion .header {
  cursor: pointer;
}

.accordion .header-icon {
  position: absolute;
  top: 5px;
  right: 25px;
  transform: rotate(0deg);
  transition-duration: 0.3s;
}

.accordion .body {
  overflow: hidden;
  transition: 700ms ease-out;
}

.accordion .body-inner {
  overflow-wrap: break-word;
}

.accordion .header-icon.rotate {
  transform: rotate(180deg);
  transition-duration: 0.3s;
}
</style>

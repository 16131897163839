import axios from "axios";
// PMI Mini VIVI Auction api
const amsapi = axios.create({
  timeout: 120000,
  baseURL: process.env.VUE_APP_API_ENDPOINT,
});

amsapi.interceptors.request.use(function(config) {
  // Do something before request is sent

  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;

  config.headers["line-token"] = localStorage.getItem(`lineToken`);

  return config;
});

// Add a response interceptor
amsapi.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    if (error.response.status === 401) {
      console.error(`Unauthorized`);
    }
    return Promise.reject(error);
  }
);

export default amsapi;

<template>
  <div
    class="status-bar"
    :class="{
      end: status == 'end',
      aborted: status == 'aborted',
      delisted: status == 'delisted',
      posted: status == 'posted',
      auction: type == 'auction',
      luckydraw: type == 'luckydraw',
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "StatusBar",
  props: ["type", "status"],
};
</script>

<style lang="scss" scoped>
@import "@/styles/_color.scss";

.status-bar {
  top: 0px;
  z-index: 2;
  width: 100%;
  height: 55px;
  position: fixed;
  max-height: 55px;
  padding: 5px 12px;
  overflow-y: hidden;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.auction {
  background-color: $success-color;
}

.luckydraw {
  background-color: $warning-color;
}

.auction,
.luckydraw {
  display: flex;

  span {
    color: #ffffff;
    font-weight: 500;

    &:nth-last-child(1) {
      text-align: right;
      flex-grow: 1 !important;
    }
  }

  &.posted {
    background-color: $default-color;
  }

  &.end {
    background-color: $danger-color;
  }

  &.aborted {
    background-color: $danger-color;
  }

  &.delisted {
    background-color: $danger-color;
  }
}
</style>

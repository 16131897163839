<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <Accordion state="expand">
          <div slot="header">
            <p class="title d-flex align-items-center mb-2">
              รายชื่อผู้ชนะการประมูล
            </p>
          </div>
          <div class="card">
            <table class="table table-hover auction-table fixed-header">
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>ชื่อ-นามสกุล</th>
                  <th>คะแนนที่ใช้</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(history, index) in histories"
                  :key="history.id"
                  :class="{
                    active: history.user_id == user,
                  }"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    <div>
                      {{ history.firstname }}
                      <br />
                      {{ history.lastname }}
                    </div>
                  </td>
                  <td>{{ history.points | numberWithCommas }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Accordion>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["histories", "user"],
};
</script>

<style lang="scss" scoped>
@import "@/styles/history.table";
</style>

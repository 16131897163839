import gql from "graphql-tag";

import store from "@/store";
import { getLotById, getAllEvents, setReadEvent } from "@/api/http";

export default {
  apollo: {
    $subscribe: {
      eventAdded: {
        query: gql`
          subscription {
            eventAdded {
              lot_id
              user_id
              event
              points
              event_id
              product_name
            }
          }
        `,
        result({ data }) {
          this.subscriptionsHandle(data);
        },
      },
    },
  },
  methods: {
    async subscriptionsHandle(data) {
      const {
        lot_id,
        user_id,
        event,
        event_id,
        product_name,
      } = data.eventAdded;

      const userId = this.$route.query.user_id;

      switch (event) {
        case "success_bid":
          if (+userId === user_id) {
            this.showAlert(
              `คุณได้เข้าร่วมการประมูลแล้ว อย่าลืมเข้ามาติดตามลำดับการประมูลของคุณนะคะ`,
              null,
              `primary`
            );
          }
          break;

        case "success_luckydraw":
          if (+userId === user_id) {
            this.showAlert(
              `ขอแสดงความยินดีด้วย คุณทำการร่วมจับรางวัลสำเร็จ กรุณารีเฟรชที่หน้าจอเพื่อแสดงคะแนนล่าสุดที่คุณมี`
            );
          }
          break;

        case "fail_bid":
          if (+userId === user_id) {
            this.showAlert(
              `ขออภัย ระบบมีปัญหาขัดข้อง ไม่สามารถทำรายการ ${lot_id} - ${product_name ||
                ""} ได้ กรุณาทำรายการใหม่อีกครั้ง`
            );
          }
          break;

        case "fail_deduct":
          if (+userId === user_id) {
            this.showAlert(
              `คะแนนของคุณไม่เพียงพอ เข้าไปทำกิจกรรมสนุกๆรับคะแนนเพิ่มนะคะ`
            );
          }
          break;

        case "fail_luckydraw":
          if (+userId === user_id) {
            this.showAlert(
              `ขออภัย ระบบมีปัญหาขัดข้อง ไม่สามารถทำรายการ ${lot_id} - ${product_name ||
                ""} ได้ กรุณาทำรายการใหม่อีกครั้ง`
            );
          }
          break;

        case "refund_bid":
          if (+userId === user_id) {
            this.showAlert(
              `ขณะนี้มีผู้ประมูลด้วยคะแนนมากกว่าคุณ เราได้คืนคะแนนให้คุณแล้ว นำคะแนนไปสู้ต่อนะคะ`,
              null,
              `primary`
            );
          }
          break;

        case "refund_bid_abort":
          if (+userId === user_id) {
            this.showAlert(
              `คุณได้รับการคืนคะแนน เนื่องจากกิจกรรม ${lot_id} - ${product_name ||
                ""} ถูกยกเลิก กรุณากดรีเฟรชที่หน้าจอเพื่อดูคะแนน`
            );
          }
          break;

        case "refund_luckydraw":
          if (+userId === user_id) {
            this.showAlert(
              `คุณได้รับการคืนคะแนน เนื่องจากกิจกรรม ${lot_id} - ${product_name ||
                ""} ถูกยกเลิก กรุณากดรีเฟรชที่หน้าจอเพื่อดูคะแนน`
            );
          }
          break;

        case "max_bid":
          const response = await getLotById(lot_id);
          const item = Object.assign({}, { id: lot_id }, response.data);

          if (+userId === user_id) {
            this.showAlert(`ยินดีด้วย คุณเป็นผู้ชนะการประมูลรายการนี้`);
          } else {
            if (
              item.status === "end" &&
              item.maximum_points !== null &&
              item.bid_points === item.maximum_points
            ) {
              this.showAlert(
                `รายการประมูลสิ้นสุดลงแล้ว เนื่องจากมีผู้ใช้คะแนนในการแลกสินค้าเข้ามาค่ะ`
              );
            } else if (item.status === "end") {
              this.showAlert(`เสียใจด้วย การประมูลสิ้นสุดลงแล้ว`);
            }
          }
          break;

        case "lot_ongoing":
          updateLotStatus(lot_id, "ongoing");
          break;

        case "lot_end":
          updateLotStatus(lot_id, "end");
          break;

        case "lot_aborted":
          this.onLotAbort(lot_id);
          break;

        default:
          break;
      }

      if (+this.item.id === lot_id) {
        // update lot data
        this.fetchLot();
      }

      if (event_id && +userId === user_id) {
        setReadEvent(event_id);
      }
    },

    updateLotStatus(id, status) {
      if (+this.item.id === lot_id) {
        this.item.status = status;
        return;
      }
    },

    onLotAbort(lot_id) {
      if (+this.item.id === lot_id) {
        this.$refs.form.canBid = false;
        return;
      }
    },
  },
};

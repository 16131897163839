<template>
  <div id="app">
    <Overlay v-if="isLoading" />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",

  computed: {
    ...mapGetters({
      isLoading: "loading/isLoading",
    }),
  },
};
</script>

<style lang="scss">
@import "node_modules/v-slim-dialog/dist/v-slim-dialog";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/@braid/vue-formulate/themes/snow/snow.scss";
@import "@/styles/app.scss";
</style>

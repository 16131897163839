<template>
  <div class="header">
    <p class="title flex-grow-1">{{ item.name }}</p>
    <div class="favorite">
      <Favorite :item="item" />
      <p>{{ item.no_favorite | numberWithCommas }} คน</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;

  .title {
    line-height: 1;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .favorite {
    width: auto;
    min-width: 80px;
    text-align: center;
  }
}
</style>

<template>
  <div id="claim-success">
    <div class="hero">
      <img src="@/assets/icon-correct.png" />
      <h1>คุณได้ทำการกรอกข้อมูล<br />เรียบร้อยแล้ว</h1>
    </div>
    <p class="body">
      กรุณารอรับของรางวัล<br />หากมีข้อสงสัยกรุณาติดต่อ น้องจริงใจ<br />
      ที่ <a href="https://th.pmiandu.com/s">LINE VIP by PMTT</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "ClaimSuccess",
};
</script>

<style lang="scss" scoped>
#claim-success {
  width: 100%;
  height: 100%;
  position: relative;

  color: #ffffff;
  text-align: center;

  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/Background.png");

  .hero {
    top: 10%;
    position: relative;

    img {
      margin-bottom: 1rem;
    }

    h1 {
      font-size: 2rem;
    }
  }

  .body {
    color: #333;
    line-height: 1;
    font-size: larger;
    font-weight: normal;

    top: 30%;
    position: relative;
  }
}
</style>

<template>
  <div id="title">
    <p class="title">{{ item.name }}</p>
    <p class="description">{{ description }}</p>
  </div>
</template>

<script>
export default {
  props: ["item"],

  computed: {
    description() {
      const { description } = this.item;

      if (!description) return "";

      let text = description.substr(0, 30);
      return text + description.length > 30 ? "..." : "";
    },
  },
};
</script>

<style lang="scss" scoped>
#title {
  padding: 10px 20px;

  .title {
    line-height: 1;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .description {
    line-height: 1;
    font-size: 22px;
    margin-bottom: 0px;
  }
}
</style>

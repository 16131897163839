<template>
  <div class="vld-parent">
    <loading
      :active="true"
      :is-full-page="true"
      color="#007aff"
      :opacity="opacity"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Overlay",
  props: {
    opacity: {
      type: Number,
      default: 0.5,
    },
  },
  components: {
    Loading,
  },
};
</script>

import {
  filterAddress,
  filterAddressByName,
  getDistrictList,
  getAmphoeList,
  getProvinceList,
  getZipCode,
} from "@/assets/json/addressThailand.js";

export default {
  data() {
    return {
      zipcode_selected: "",
      province_selected: "",
      amphoe_selected: "",
      district_selected: "",
    };
  },

  watch: {
    province_selected() {
      this.amphoe_selected = "";
      this.district_selected = "";
    },
  },

  methods: {
    getAddress() {
      const province = filterAddress("province_code", this.province_selected);
      const amphoe = filterAddress("amphoe_code", this.amphoe_selected);
      const district = filterAddress("district_code", this.district_selected);
      const zipcode = filterAddress("zipcode", this.zipcode_selected);

      return {
        province: province?.province || "",
        amphoe: amphoe?.amphoe || "",
        district: district?.district || "",
        zipcode: zipcode?.zipcode || "",
      };
    },

    getAddressByName(key, value) {
      const address = filterAddressByName(key, value);
      return address || null;
    },

    getZipCode() {
      return getZipCode(this.amphoe_selected).map((o) => ({
        label: o,
        value: o,
      }));
    },

    getDistrictList(amphoe_code) {
      return getDistrictList(amphoe_code).map((o) => ({
        label: o.district,
        value: o.district_code,
      }));
    },

    getAmphoeList(province_code) {
      return getAmphoeList(province_code).map((o) => ({
        label: o.amphoe,
        value: o.amphoe_code,
      }));
    },

    getProvinceList() {
      return getProvinceList().map((o) => ({
        label: o.province,
        value: o.province_code,
      }));
    },
  },
};

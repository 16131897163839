import { getUserInfo } from "@/api/http";

import * as api from "@/api/http";
import liff from "@line/liff";

export default {
  namespaced: true,

  state: {
    accessToken: "",
    userInfo: null,
    lineProfile: null,
    prefill: null,
  },

  getters: {
    accessToken(state) {
      return state.accessToken;
    },
    userInfo(state) {
      return state.userInfo;
    },
    lineProfile(state) {
      return state.lineProfile;
    },
    prefill(state) {
      return state.prefill;
    },
  },

  actions: {
    async fetchUserInfo({ commit }) {
      await api
        .getUserInfo()
        .then((response) => commit("SET_USER_INFO", response.data));
    },
    async getLineProfile({ commit }) {
      const profile = await liff.getProfile();
      const lineToken = liff.getAccessToken();

      commit("SET_TOKEN", lineToken);
      commit("SET_LINE_PROFILE", profile);

      localStorage.setItem("lineToken", lineToken);
    },
  },

  mutations: {
    SET_TOKEN(state, payload) {
      state.accessToken = payload;
    },
    SET_USER_INFO(state, payload) {
      state.userInfo = payload;
    },
    SET_LINE_PROFILE(state, payload) {
      state.lineProfile = payload;
    },
    SET_PRE_FILL(state, payload) {
      state.prefill = payload;
    },
  },
};

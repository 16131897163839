import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import liff from "@line/liff";
import VConsole from "vconsole";
import SlimDialog from "v-slim-dialog";
import VueFormulate from "@braid/vue-formulate";
import VueCountdown from "@chenfengyuan/vue-countdown";

import Money from "v-money";
import VModal from "vue-js-modal";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import "./mixins";
import "./filters";

import { apolloProvider } from "./utils/graphql";

import HeartIcon from "@/assets/svg/heart.svg";
import HeartAltIcon from "@/assets/svg/heart_alt.svg";
import AuctionIcon from "@/assets/svg/auction.svg";
import LuckyDrawIcon from "@/assets/svg/luckydraw.svg";

import Navbar from "@/components/shared/Navbar.vue";
import Footer from "@/components/shared/Footer.vue";
import Favorite from "@/components/shared/Favorite.vue";

import Overlay from "@/components/shared/Overlay.vue";
import Accordion from "@/components/shared/Accordion.vue";
import AlertSheet from "@/components/sheet/AlertSheet.vue";

Vue.use(Loading);
Vue.use(SlimDialog);
Vue.use(VueFormulate);
Vue.use(Money, { precision: 0 });

Vue.component("Overlay", Overlay);
Vue.component("Accordion", Accordion);
Vue.component("HeartIcon", HeartIcon);
Vue.component("HeartAltIcon", HeartAltIcon);
Vue.component("AuctionIcon", AuctionIcon);
Vue.component("LuckyDrawIcon", LuckyDrawIcon);

Vue.component("Navbar", Navbar);
Vue.component("Footer", Footer);
Vue.component("Favorite", Favorite);
Vue.component("AlertSheet", AlertSheet);

Vue.component(VueCountdown.name, VueCountdown);

Vue.use(VModal, {
  dialog: true,
  dynamic: true,
  injectModalsContainer: true,
  dynamicDefaults: {
    clickToClose: true,
  },
});

Vue.config.productionTip = false;

if (+process.env.VUE_APP_DEBUG_MODE == 1) {
  new VConsole();
}

const bootstrap = async function() {
  await liff.init({
    liffId: process.env.VUE_APP_LIFF_ID || "123456789-ABCDEF",
  });

  new Vue({
    router,
    store,
    apolloProvider,
    render: (h) => h(App),
  }).$mount("#app");
};

bootstrap();

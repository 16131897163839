<template>
  <div class="terms">
    <div class="nav-bar">
      <button class="btn btn-link pb-0 px-0" @click="$emit('back')">
        <span class="lnr lnr-chevron-left mr-2"></span>
        <span>ย้อนกลับ</span>
      </button>
    </div>
    <div class="container" v-html="terms"></div>
  </div>
</template>

<script>
export default {
  props: ["terms"],
};
</script>
<style lang="scss" scoped>
@import "@/styles/_color.scss";
.terms {
  top: 55px;
  left: 0px;
  z-index: 2;
  width: 100%;
  position: fixed;
  overflow-y: hidden;
  background-color: #fff;
  height: calc(100vh - 55px);
  max-height: calc(100vh - 55px);

  .nav-bar {
    width: 100%;
    display: flex;
    position: fixed;
    padding: 5px 12px;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;

    span.lnr {
      font-size: 16px;
      color: $primary-color;
    }

    .btn-link {
      align-self: center;
      text-decoration: none;
    }
  }

  .container {
    width: 100%;
    margin-top: 55px;
    text-align: left;
    overflow-y: scroll;
    background-color: #fff;
    height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);

    padding-bottom: 100px;
  }
}
</style>

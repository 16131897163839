<template>
  <div class="form container-fluid" v-if="userInfo">
    <Overlay v-if="isLoading" />
    <p class="title">เลือกคะแนนที่ต้องการประมูลเพิ่ม</p>
    <div class="row">
      <div class="btn-group btn-group-toggle col-12" data-toggle="buttons">
        <label
          class="btn"
          :disabled="userInfo.points < minimumBidPoint || !isOngoing"
          :class="{ active: minimumBidPoint + presetPoints[0] === point }"
        >
          <input
            type="radio"
            name="options"
            :disabled="userInfo.points < minimumBidPoint || !isOngoing"
            :value="presetPoints[0]"
            v-model="selectedPoint"
          />
          +{{ presetPoints[0] | numberWithCommas }}
        </label>
        <label
          class="btn"
          :disabled="userInfo.points < minimumBidPoint || !isOngoing"
          :class="{ active: minimumBidPoint + presetPoints[1] === point }"
        >
          <input
            type="radio"
            name="options"
            :disabled="userInfo.points < minimumBidPoint || !isOngoing"
            :value="presetPoints[1]"
            v-model="selectedPoint"
          />
          +{{ presetPoints[1] | numberWithCommas }}
        </label>
        <label
          class="btn"
          :disabled="userInfo.points < minimumBidPoint || !isOngoing"
          :class="{ active: minimumBidPoint + presetPoints[2] === point }"
        >
          <input
            type="radio"
            name="options"
            :disabled="userInfo.points < minimumBidPoint || !isOngoing"
            :value="presetPoints[2]"
            v-model="selectedPoint"
          />
          +{{ presetPoints[2] | numberWithCommas }}
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="form-text mt-3">
          พิมพ์คะแนนทั้งหมดที่ต้องการประมูล
        </p>
        <div class="form-group">
          <money
            v-model="point"
            v-bind="money"
            :disabled="!canBid"
            class="form-control"
          ></money>
        </div>
        <p
          v-if="userInfo.points < minimumBidPoint && isOngoing"
          class="text-danger"
        >
          คะแนนของคุณไม่เพียงพอ เข้าไปทำกิจกรรมสนุกๆรับคะแนนเพิ่มนะคะ
        </p>
        <p v-if="errorMessage != ''" class="text-danger">
          <span class="text-primary" v-html="errorMessage"></span>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p class="term-link-block">
          <button class="btn btn-link" @click="showTermsModal = true">
            เงื่อนไขและข้อตกลง
          </button>
        </p>
        <button
          type="button"
          :disabled="!canBid"
          @click.stop="confirm"
          class="btn btn-primary btn-lg btn-block"
        >
          <AuctionIcon class="mx-2" />
          กดเพื่อร่วมประมูล
        </button>
      </div>
    </div>
    <transition
      enter-active-class="animated slideInRight"
      leave-active-class="animated slideOutRight"
    >
      <Terms
        v-if="showTermsModal"
        @back="showTermsModal = false"
        :terms="item.term_condition"
      />
    </transition>
    <transition
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <ConfirmSheet
        v-if="showConfirmModal"
        @cancel="closeConfirmModal"
        @submit="submit"
      >
        <div class="text-center">
          <p class="h2 my-4">
            ยืนยัน ใช้
            {{ point | numberWithCommas }} คะแนน<br />เพื่อร่วมประมูล
          </p>
          <p class="h3 text-danger">หากคุณไม่ใช่ผู้ชนะจะได้คะแนนคืน</p>
        </div>
      </ConfirmSheet>
    </transition>
  </div>
</template>

<script>
import { bid } from "@/api/http";
import { mapGetters } from "vuex";
import validationMixin from "@/views/Auction/mixins/validations";
import Terms from "@/views/Auction/components/Terms.vue";
import ConfirmSheet from "@/components/sheet/ConfirmSheet.vue";

export default {
  name: "AuctionForm",

  props: ["item"],

  mixins: [validationMixin],

  components: {
    Terms,
    ConfirmSheet,
  },

  data() {
    return {
      id: null,
      point: 0,
      canBid: true,
      isLoading: true,
      hasSumbit: false,
      errorMessage: "",
      selectedPoint: 0,
      showTermsModal: false,
      showConfirmModal: false,

      money: {
        decimal: ",",
        thousands: ",",
        prefix: "",
        suffix: " คะแนน",
        precision: 0,
        masked: false,
      },

      bidError: "",
    };
  },

  watch: {
    selectedPoint(v) {
      this.setPoint();
      this.errorMessage = "";
    },

    item(v) {
      this.setCanBid();
    },
  },

  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
    }),

    isOngoing() {
      return this.item.status == "ongoing";
    },

    presetPoints() {
      const { minimum_increment } = this.item;
      return [
        minimum_increment * 1,
        minimum_increment * 2,
        minimum_increment * 4,
      ];
    },

    minimumBidPoint() {
      return this.item.bid_points > 0
        ? this.item.bid_points
        : this.item.minimum_points;
    },
  },

  methods: {
    setPoint() {
      this.point = this.minimumBidPoint + this.selectedPoint;
    },

    setCanBid() {
      setTimeout(() => {
        this.canBid =
          this.isOngoing &&
          this.userInfo.points > this.minimumBidPoint &&
          this.item.bid_points !== this.item.maximum_points;
      }, 500);
    },

    validate() {
      this.hasSumbit = true;
      this.errorMessage = ``;

      if (this.isEmptyOrZeroPoint(this)) {
        this.showErrorMessageEmptyOrZeroPoint(this);
        return false;
      }

      if (this.isLessThanMinimumPoint(this)) {
        this.showErrorMessageMinimumPoint(this);
        return false;
      }

      if (this.isLessThanMinimumPoint(this)) {
        this.showErrorMessageMinimumPoint(this);
        return false;
      }

      return true;
    },

    confirm() {
      if (!this.validate()) {
        return;
      }

      this.openConfirmModal();
    },

    async submit() {
      this.bidError = "";
      this.isLoading = true;

      try {
        await bid(this.id, this.point);
        this.isLoading = false;
        this.closeConfirmModal();
        this.$emit("success");
      } catch (error) {
        this.bidError = error.response.data;
        setTimeout(() => {
          this.isLoading = false;
          this.closeConfirmModal();
          this.emitError();
        }, 700);
      }
    },

    emitError(error) {
      setTimeout(() => {
        this.$emit("error", this.mappingBidErrorMessage());
      }, 500);
    },

    mappingBidErrorMessage() {
      let message = this.bidError;
      switch (true) {
        case message == "You have been outbid":
          message = `มีผู้ร่วมประมูลที่ให้คะแนนมากกว่าคุณ`;
          break;
        case message == "Not enough points":
          message = `คะแนนของคุณไม่เพียงพอ เข้าไปทำกิจกรรมสนุกๆรับคะแนนเพิ่มนะคะ`;
          break;
        case message == "bid_points is below minimum":
          const point = this.minimumBidPoint + this.item.minimum_increment;
          message = `อย่าพลาด!!โอกาสเป็นเจ้าของรางวัลนี้ <br/> กรุณาใส่คะแนนที่ต้องการประมูลอย่างน้อย ${point} คะแนน`;
          break;
        case message == "Cannot bid now":
          message = `เสียใจด้วยสิ้นสุดการประมูลแล้ว`;
          break;
      }
      return message;
    },

    openConfirmModal() {
      this.showConfirmModal = true;
    },

    closeConfirmModal() {
      this.showConfirmModal = false;
    },
  },

  async created() {
    this.isLoading = false;
  },

  mounted() {
    this.id = this.$route.query.lot_id;
    this.point = this.item.minimum_points;
    this.setCanBid();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_color.scss";

.form {
  text-align: center;

  .title {
    font-size: 30px;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .form-text,
  .form-group {
    margin-bottom: 10px;
  }

  .form-text {
    font-size: 30px;
  }

  .term-link-block {
    margin-bottom: 0px;

    button {
      font-size: 30px;
    }
  }

  input[type="tel"] {
    font-size: 30px;
    font-weight: 300;
    border-radius: 10px;
    border-color: #efefef;
    background-color: #efefef;
    height: calc(1.25em + 0.5rem + 2px);

    &[disabled="disabled"] {
      opacity: 0.5;
    }
  }
}

.btn-group-toggle {
  .btn {
    color: #000;
    line-height: 1;
    font-size: 30px;
    font-weight: 500;
    border-width: 3px;
    border-radius: 10px;
    border-color: #efefef;
    background-color: #efefef;

    &.active {
      border-color: $primary-color;
    }

    &:nth-child(2) {
      margin-left: 10px;
      margin-right: 10px;
      border-radius: 10px;
    }

    &[disabled="disabled"] {
      opacity: 0.45;
    }
  }
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.btn-block {
  font-size: 30px;
  line-height: 1;
}

.animated {
  animation-duration: 0.45s;
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
</style>

import qs from "qs";
import amsapi from "@/api/instance";

export async function endCampaignLogin(body) {
  return await amsapi.post(`/liff/end/login`, body);
}

export async function winnerLogin(body) {
  return await amsapi.post(`/liff/winner/login`, body);
}

export async function fillWinner(form) {
  return await amsapi.post(`/liff/winner/fill_winner`, form);
}

export async function getUserInfo() {
  return await amsapi.get(`/liff/api/user/info`);
}

export async function getMyList(params) {
  const queryString = qs.stringify(params, {
    addQueryPrefix: true,
  });

  return await amsapi.get(`/liff/api/lot/my${queryString}`);
}

export async function addFavorite(id) {
  return await amsapi.post(`/liff/api/lot/${id}/add_favorite`);
}

export async function removeFavorite(id) {
  return await amsapi.post(`/liff/api/lot/${id}/remove_favorite`);
}

export async function draw(id, point) {
  return await amsapi.post(`/liff/api/lot/${id}/luckydraw`, {
    luckydraw_chances: point,
  });
}

export async function getLotById(id) {
  return await amsapi.get(`/liff/api/lot/${id}`);
}

export async function bid(id, point) {
  return await amsapi.post(`/liff/api/lot/${id}/bid`, { bid_points: point });
}

export async function getAllEvents() {
  return await amsapi.get(`/liff/api/event`);
}

export async function setReadEvent(id) {
  return await amsapi.post(`/liff/api/event/read/${id}`);
}

<template>
  <div class="login">
    <div class="logo">
      <img src="@/assets/logo.png" alt="VIP by PMTT" />
    </div>
    <div class="action">
      <button class="md-raised md-primary" @click="login()">
        Login with LINE
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",

  data() {
    return { callback: "" };
  },

  methods: {
    login() {
      const query = this.$route.query;

      const callback = `${
        process.env.VUE_APP_AUTH_CALLBACK
      }?next=${encodeURIComponent(query.callback)}`;

      liff.login({
        redirectUri: callback,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  img {
    width: 50%;
    border-radius: 22%;
    box-shadow: 0 0.25rem 0.5rem #ccc;
  }

  text-align: center;
  margin: 10rem auto;
}

.action {
  width: 100%;
  margin: 0 auto;
  text-align: center;

  button {
    color: #fff;
    min-width: 60%;
    font-size: 2rem;
    font-weight: 600;
    border-radius: 1rem;
    box-shadow: 0 0.25rem 0.5rem #ccc;

    border: 1px solid rgb(206, 41, 60);
    background: rgb(206, 41, 60);
    background: linear-gradient(
      0deg,
      rgba(206, 41, 60, 1) 0%,
      rgba(250, 75, 54, 1) 100%
    );
  }
}
</style>

import moment from "moment";

export const transformCountdownProps = function(props) {
  Object.entries(props).forEach(([key, value]) => {
    // Adds leading zero
    const digits = value < 10 ? `0${value}` : value;

    // uses singular form when the value is less than 2
    const word = value < 2 ? key.replace(/s$/, "") : key;

    props[key] = `${digits}`;
  });

  return props;
};

export const getAuctionDetailStatus = function(item) {
  let status = "";
  let bgClass = "";
  let statusText = "";
  let moreThanTwoDays = false;

  const diffHours = moment(item.end_date).diff(moment(), "hours");

  switch (true) {
    case item.status == "posted":
      status = "posted";
      bgClass = "bg-default";
      statusText = "ยังไม่เริ่มการประมูล";
      break;
    case item.status == "ongoing":
      if (diffHours > 48) {
        status = "ongoing";
        bgClass = "bg-success";
        moreThanTwoDays = true;
        statusText = "กำลังประมูล..";
      } else if (diffHours < 48 && diffHours >= 0) {
        status = "ongoing";
        bgClass = "bg-success";
        moreThanTwoDays = false;
        statusText = "กำลังประมูล..";
      }
      break;
    case item.status == "end" || item.status == "aborted" || diffHours < 0:
      status = item.status;
      bgClass = "bg-danger";
      statusText = "สิ้นสุดการประมูล";
      break;
  }

  return { bgClass, statusText, status, moreThanTwoDays };
};

export const getLuckyDrawDetailStatus = function(item) {
  let status = "";
  let bgClass = "";
  let statusText = "";
  let moreThanTwoDays = false;

  const diffHours = moment(item.end_date).diff(moment(), "hours");

  switch (true) {
    case item.status == "posted":
      status = "posted";
      bgClass = "bg-default";
      statusText = "ใช้คะแนนร่วมจับของรางวัล";
      break;
    case item.status == "ongoing":
      if (diffHours > 48) {
        status = "ongoing";
        moreThanTwoDays = true;
        bgClass = "bg-warning";
        statusText = "ใช้คะแนนร่วมจับของรางวัล";
      } else if (diffHours < 48 && diffHours >= 0) {
        status = "ongoing";
        moreThanTwoDays = false;
        bgClass = "bg-warning";
        statusText = "ใช้คะแนนร่วมจับของรางวัล";
      }
      break;
    case item.status == "end" || item.status == "aborted" || diffHours < 0:
      status = item.status;
      bgClass = "bg-danger";
      statusText = "สิ้นสุดแล้ว";
      break;
  }

  return { bgClass, statusText, status, moreThanTwoDays };
};

export const getHistoryStatusText = function(item) {
  let statusText = "";
  switch (item.type) {
    case "auction":
      statusText = item.is_winner == 1 ? "ชนะการประมูล" : "ไม่ชนะการประมูล";
      break;
    case "luckydraw":
      statusText =
        item.is_winner == 1 ? "ชนะการจับของรางวัล" : "ไม่ชนะการจับของรางวัล";
      break;
  }
  return statusText;
};

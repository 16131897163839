<template>
  <swiper class="swiper" :options="swiperOption">
    <swiper-slide v-for="(image, index) in images" :key="index">
      <img :src="`${image}?cache=${new Date().getTime()}`" />
    </swiper-slide>
    <div
      class="swiper-pagination swiper-pagination-alt"
      slot="pagination"
    ></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "ImageSwiper",

  props: ["images"],

  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_color.scss";

.swiper {
  width: 100%;
}

.swiper,
.swiper-slide > img {
  height: 240px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
</style>

<style lang="scss">
@import "@/styles/vendors/swiper.scss";

.swiper-pagination-alt {
  .swiper-pagination-bullet {
    opacity: 1;
    width: 10px;
    height: 10px;
    border: none;
    margin: 0 3px;
    background: #ebedec;

    &.swiper-pagination-bullet-active {
      background-color: #0179ff;
    }
  }
}
</style>

<template>
  <div v-html="message"></div>
</template>

<script>
export default {
  props: ["message"],
};
</script>

<style>
.vm--overlay {
  background: transparent;
}

.vm--modal.result-message {
  left: 0px !important;
  padding: 12px;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  margin: 0px auto;
  position: relative;
  border-radius: 5px;
  text-align: center;
  background-color: #000000d6;
}
</style>

<template>
  <div class="wrapper" v-if="item">
    <StatusBar :item="item" />
    <Header :item="item" />
    <Swiper :images="images" />
    <LotNo :item="item" />
    <Form ref="form" :item="item" @success="onBidSuccess" @error="onBidError" />
    <DateRange :item="item" />
    <History :user="userId" v-if="userId" :histories="bidHistory" />
    <Info :item="item" />
    <transition
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <AlertSheet v-if="showAlertSheet" @submit="showAlertSheet = false">
        <div class="text-center">
          <p class="h2 my-4">
            {{ bidErrorMessage }}
          </p>
        </div>
      </AlertSheet>
    </transition>
  </div>
</template>

<script>
import { getLotById } from "@/api/http";
import { mapActions, mapGetters } from "vuex";
import StatusBar from "@/views/Auction/components/StatusBar.vue";
import Header from "@/views/Auction/components/Header.vue";
import Swiper from "@/views/Auction/components/Swiper.vue";
import DateRange from "@/views/Auction/components/DateRange.vue";
import Form from "@/views/Auction/components/Form.vue";
import History from "@/views/Auction/components/History.vue";
import Info from "@/views/Auction/components/Info.vue";
import LotNo from "@/views/Auction/components/LotNo.vue";
import EndedSheet from "@/views/Auction/components/EndedSheet.vue";
import PopupOverlay from "@/components/shared/Popup.vue";

import subscriptions from "@/mixins/subscriptions.mixins";

export default {
  name: "AuctionDetail",

  mixins: [subscriptions],

  components: {
    StatusBar,
    Header,
    Swiper,
    DateRange,
    Form,
    History,
    Info,
    LotNo,
    EndedSheet,
  },

  data() {
    return {
      item: {},
      userId: null,
      bidErrorMessage: "",
      showAlertSheet: false,
    };
  },

  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      lineProfile: "user/lineProfile",
    }),

    images() {
      const { image1, image2, image3, image4, image5 } = this.item;
      return [image1, image2, image3, image4, image5].filter((i) => i != null);
    },

    bidHistory() {
      return this.item.bid_history ? this.item.bid_history : [];
    },
  },

  async created() {
    await this.fetchUserInfo();
    await this.fetchLot();
    this.userId = this.$route.query.user_id;
  },

  methods: {
    ...mapActions({ fetchUserInfo: "user/fetchUserInfo" }),

    async fetchLot() {
      try {
        const id = this.$route.query.lot_id;
        const response = await getLotById(id);
        this.item = Object.assign({}, { id: id }, response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async onBidSuccess() {
      this.$modal.show(
        PopupOverlay,
        { message: `คำสั่งของคุณกำลังถูกดำเนินการ` },
        {
          width: "80%",
          height: "auto",
          classes: "result-message",
        }
      );

      setTimeout(this.$modal.hideAll, 3000);
    },

    async onBidError(message) {
      this.bidErrorMessage = message;
      this.showAlertSheet = true;
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-top: 55px;
  background-color: #f9fbfc;
}

.animated {
  animation-duration: 0.45s;
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
</style>

<template>
  <div :class="['form-group', !!error && 'has-error']">
    <label v-if="label" class="form-label" :for="name">
      {{ label }}<span class="red">*</span>
    </label>
    <input
      :id="name"
      :name="name"
      :type="type"
      :value="value"
      @input="$emit('input', $event.target.value)"
      class="form-input"
      :class="classes"
      :placeholder="placeholder"
      @blur="$emit('validate')"
      @keypress="$emit('validate')"
    />
    <p class="form-input-hint" v-if="!!error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: "form-input",
  props: {
    type: { required: true },
    label: { required: false },
    name: { required: true },
    value: { required: true },
    error: { required: true },
    classes: { required: false },
    placeholder: { required: false },
  },
};
</script>

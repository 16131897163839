import * as Yup from "yup";
import { isValidThaiNationalID } from "@/libs/commons";

export const formObject = {
  values: {
    firstname: "",
    lastname: "",
    house_no: "",
    road: "",
    tambon: "",
    amphoe: "",
    province: "",
    zipcode: "",
    telephone: "",
    id_card_no: "",
    tax_document: "none",
    email: "",
    license_confirm: false,
    age_confirm: false,
  },
  errors: {
    firstname: "",
    lastname: "",
    house_no: "",
    road: "",
    tambon: "",
    amphoe: "",
    province: "",
    zipcode: "",
    telephone: "",
    id_card_no: "",
    tax_document: "none",
    email: "",
    license_confirm: false,
    age_confirm: false,
    address: false,
  },
};

const { object, string, number } = Yup;

Yup.addMethod(Yup.mixed, "thaiIDCard", function(message) {
  return this.test("thaiIDCard", message, function(value) {
    return isValidThaiNationalID(value);
  });
});

export const formSchema = object().shape({
  firstname: string().required("กรุณากรอกชื่อให้ถูกต้อง"),
  lastname: string().required("กรุณากรอกนามสกุลให้ถูกต้อง"),
  // house_no: string().required("กรุณากรอกที่อยู่จัดส่ง ให้ถูกต้อง"),
  // road: string().required("กรุณาระบุถนน ให้ถูกต้อง"),
  // tambon: string().required("กรุณาระบุตำบล/แขวง"),
  // amphoe: string().required("กรุณาระบุอำเภอ/เขต"),
  // province: string().required("กรุณาระบุจังหวัด"),
  // zipcode: number().required("กรุณาระบุรหัสไปรษณีย์"),
  telephone: string()
    .min(9, "กรุณากรอกเบอร์โทรศัพท์")
    .max(10, "กรุณากรอกเบอร์โทรศัพท์")
    .required("กรุณากรอกเบอร์โทรศัพท์"),

  id_card_no: Yup.string().thaiIDCard(
    "กรุณากรอกเลขที่บัตรประชาชน 13 หลักให้ถูกต้อง"
  ),

  tax_document: string(),
});

import { getMyList } from "@/api/http";

export default {
  namespaced: true,

  state: {
    lots: [],
    defaultParams: {
      type: localStorage.getItem("favorite_type") || "",
      order_by: localStorage.getItem("favorite_order_by") || "earliest_start",
    },
  },

  getters: {
    list(state) {
      return state.lots;
    },

    params(state) {
      return state.defaultParams;
    },

    getQueryParams(state) {
      let params = {};
      for (var key in state.defaultParams) {
        if (state.defaultParams[key]) {
          params[key] = state.defaultParams[key];
        }
      }
      return params;
    },
  },

  actions: {
    async fetch({ commit, getters }) {
      const params = getters.getQueryParams;

      const { data } = await getMyList(params);
      commit("SET_LIST", data);
    },
    setOrderBy({ commit }, value) {
      commit("SET_ORDER_BY", value);
      value && localStorage.setItem("favorite_order_by", value);
    },
    setFilterType({ commit }, value) {
      commit("SET_FILTER_TYPE", value);
      value
        ? localStorage.setItem("favorite_type", value)
        : localStorage.removeItem("favorite_type");
    },
  },

  mutations: {
    SET_LIST(state, payload) {
      state.lots = payload;
    },
    SET_FILTER_TYPE(state, payload) {
      state.defaultParams.type = payload;
    },
    SET_ORDER_BY(state, payload) {
      state.defaultParams.order_by = payload;
    },
  },
};

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <hr />
        <div class="d-flex">
          <div class="w-25">เริ่ม</div>
          <div class="w-100 flex-shrink-1">{{ item.start_date | dateTimeMedium }} น.</div>
        </div>
        <div class="d-flex">
          <div class="w-25">สิ้นสุด</div>
          <div class="w-100 flex-shrink-1">{{ item.end_date | dateTimeMedium }} น.</div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
export default { props: ["item"] };
</script>
<style lang="scss" scoped>
.d-flex p {
  margin-bottom: 0px;
}
</style>

<template>
  <div class="sheet modal-page" :class="{ active: ready }">
    <div class="sheet-content">
      <slot />
      <div class="row row-action">
        <div class="col-6 px-2">
          <button
            type="button"
            class="btn btn-primary btn-lg btn-block"
            @click="$emit('submit')"
          >
            ยืนยัน
          </button>
        </div>
        <div class="col-6 px-2">
          <button
            type="button"
            class="btn btn-default btn-lg btn-block"
            @click="$emit('cancel')"
          >
            ยกเลิก
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmSheet",
  
  data() {
    return {
      ready: false,
    };
  },

  mounted() {
    setTimeout(() => {
      this.ready = true;
    }, 450);
  },
};
</script>

<style lang="scss" scoped>
.sheet-content {
  width: 100%;
  bottom: 0px;
  height: 315px;
  padding: 15px;
  position: absolute;
  border-radius: 1px;
  background-color: #ffffff;

  .row-action {
    width: 100%;
    bottom: 36px;
    padding: 0px 12px;
    position: absolute;

    button {
      //
    }
  }
}
</style>

<template> </template>

<script>
import { winnerLogin } from "@/api/http";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Claim",

  computed: {
    ...mapGetters({
      accessToken: "user/accessToken",
    }),
  },

  methods: {
    ...mapMutations({
      setLotData: "lot/SET_LOT_DATA",
      setPrefillForm: "user/SET_PRE_FILL",
    }),

    async init() {
      const line_token = this.accessToken;
      const param = this.$route.query.param;

      const { data } = await winnerLogin({ line_token, param });
      const { submitted, expired, have_pre_fill, pre_fill, lot_data } = data;

      this.setLotData(lot_data);

      if (submitted === 0 && expired === 1) {
        return this.$router.push({ name: "ClaimExpired" });
      }

      if (submitted === 0 && expired === 0) {
        if (have_pre_fill === 1) {
          this.setPrefillForm(pre_fill);
        }

        return this.$router.push({ name: "ClaimForm", query: { param } });
      }

      if (submitted == 1 && expired === 0) {
        return this.$router.push({ name: "ClaimSuccess" });
      }
    },
  },

  async mounted() {
    await this.init();
  },
};
</script>

<style></style>

<template>
  <div class="nav-bar">
    <button class="btn btn-link pb-0 px-0" @click="goBack()">
      <span class="lnr lnr-chevron-left mr-2"></span>
      <span>ย้อนกลับ</span>
    </button>
    <div class="counter">
      <span>จำนวนผู้เข้าชม {{ counter | numberWithCommas }} คน</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "counter"],
  methods: {
    goBack() {
      if (this.name) return this.$router.go(this.name);
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/_color.scss";
.nav-bar {
  display: flex;
  padding: 5px 12px;
  align-items: center;
  justify-content: space-between;

  span.lnr {
    font-size: 16px;
    color: $primary-color;
  }

  .btn-link {
    align-self: center;
    text-decoration: none;
  }
}
</style>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <Accordion state="expand">
          <div slot="header">
            <p class="title d-flex align-items-center mb-2">
              <span class="lnr lnr-history mr-2"></span>
              ประวัติ
            </p>
          </div>
          <div class="card">
            <table class="table table-hover auction-table fixed-header">
              <thead>
                <tr>
                  <th>ผู้ร่วมประมูล</th>
                  <th>วันที่และเวลา</th>
                  <th>คะแนน</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="history in histories"
                  :key="history.id"
                  :class="{
                    deducted: history.status === 'deducted',
                    active: history.user_id == user,
                  }"
                >
                  <td>
                    <div class="d-flex">
                      <span
                        class="rank-badge"
                        :class="{
                          winner: history.status === 'deducted',
                        }"
                      >
                        {{ history.rank }}
                      </span>
                      <div class="ml-2">
                        <span class="name">
                          {{ history.firstname }}
                          <br />
                          {{ history.lastname }}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    {{ history.bid_time | DATE_SHORT }}
                    <br />
                    {{ history.bid_time | TIME_24_WITH_SECONDS }}
                  </td>
                  <td>{{ history.points }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Accordion>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["histories", "user"],
};
</script>

<style lang="scss" scoped>
@import "@/styles/history.table";
// https://medium.com/@vembarrajan/html-css-tricks-scroll-able-table-body-tbody-d23182ae0fbc
// And comment by Alexandros Pappas
.fixed-header {
  thead,
  tbody tr,
  tfoot {
    width: 100%;
    display: table;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }

  tbody {
    display: block;
    height: auto;
    overflow: auto;
    max-height: 200px;
  }

  th:first-child {
    text-align: left;
    padding-left: 1.25em;
  }

  th:first-child,
  td:first-child {
    width: 160px;
    padding-right: 0px;
  }

  th:last-child,
  td:last-child {
    width: 70px;
  }
}
</style>

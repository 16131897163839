import Vue from "vue";
import { mapActions } from "vuex";

Vue.mixin({
  methods: {
    ...mapActions({ setLoading: "loading/setLoading" }),

    showLoading() {
      this.setLoading(true);
    },

    hideLoading() {
      this.setLoading(false);
    },

    async wait(ms) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(null);
        }, ms || 1000);
      });
    },

    async showAlert(message, options, classes) {
      setTimeout(() => {
        if (classes) {
          document
            .querySelector(`.v-dialog-body .content`)
            .classList.add(classes);
        }
      }, 100);

      return this.$dialogs.alert(message, options || { okLabel: "ตกลง" });
    },
  },
});

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"status-bar",class:{
    end: _vm.status == 'end',
    aborted: _vm.status == 'aborted',
    delisted: _vm.status == 'delisted',
    posted: _vm.status == 'posted',
    auction: _vm.type == 'auction',
    luckydraw: _vm.type == 'luckydraw',
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
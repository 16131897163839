<template>
  <div class="header">
    {{ title || "กรอกข้อมูลเพื่อรับของรางวัล" }}
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.header {
  padding: 8px;
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  background-image: url("~@/assets/images/header_bg.png");
}
</style>
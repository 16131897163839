import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";
import liff from "@line/liff";

// Auction Bidding
import Auction from "@/views/Auction/Index.vue";

// Winner notification
import Claim from "@/views/Claim/Claim.vue";
import ClaimForm from "@/views/Claim/ClaimForm.vue";
import ClaimExpired from "@/views/Claim/ClaimExpired.vue";
import ClaimSuccess from "@/views/Claim/ClaimSuccess.vue";

// End of Campaign view
import ListOfWinners from "@/views/WinnerList/WinnerList.vue";

// Auth view
import Login from "@/views/Auth/Login.vue";
import AuthCallback from "@/views/Auth/AuthCallback.vue";

import NotFound from "@/views/Error/404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/lot",
    name: "Auction",
    component: Auction,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/claim",
    name: "Claim",
    component: Claim,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/claim-form",
    name: "ClaimForm",
    component: ClaimForm,
    meta: {
      requiresAuth: true,
    },
    beforeEnter(to, from, next) {
      if (!["Claim"].includes(from.name)) {
        window.location.href = "/";
        return;
      }

      next();
    },
  },
  {
    path: "/claim-success",
    name: "ClaimSuccess",
    component: ClaimSuccess,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/claim-expired",
    name: "ClaimExpired",
    component: ClaimExpired,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/list-of-winners",
    name: "ListOfWinners",
    component: ListOfWinners,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/callback",
    name: "AuthCallback",
    component: AuthCallback,
  },
  { path: "*", component: NotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { param } = to.query;

  if (param) {
    localStorage.setItem(`accessToken`, param);
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isLoggedIn = liff.isLoggedIn();

    if (isLoggedIn) {
      const lineProfile = store.getters["user/lineProfile"];

      if (!lineProfile) {
        await store.dispatch("user/getLineProfile");
      }

      next();
      return;
    }

    next(`/login?callback=${encodeURIComponent(to.fullPath)}`);
  } else {
    next();
  }
});

export default router;

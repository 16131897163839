import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
Vue.use(Vuex);

import lot from "@/store/lot";
import user from "@/store/user";
import loading from "@/store/loading";
import favorite from "@/store/favorite";

const debug = +process.env.VUE_APP_DEBUG_MODE === 1;

const vuexLocal = new VuexPersistence({ storage: window.sessionStorage });

export default new Vuex.Store({
  state: {},
  getters: {},
  actions: {},
  mutations: {},
  modules: {
    lot,
    user,
    loading,
    favorite,
  },
  plugins: [vuexLocal.plugin],

  // https://github.com/championswimmer/vuex-persist#support-strict-mode
  strict: debug,
});

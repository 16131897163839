<template>
  <div class="footer">
    <p class="copy">© PHILIP MORRIS PRODUCTS SA</p>
    <a href="https://pmiprivacy.com/th/traders" target="_blank">
      PRIVACY NOTICE
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/_color.scss";

.footer {
  padding: 30px;
  text-align: center;
  padding-bottom: 20px;
  background-color: $primary-color;
  
  &.fixed {
    bottom: 0px;
    width: 100%;
    position: absolute;
  }

  *,
  a {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    text-transform: uppercase;
  }

  .copy {
    line-height: 1.25;
    margin-bottom: 0px;
  }

  a {
    text-decoration: underline;
  }
}
</style>

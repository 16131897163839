<template>
  <div id="winner">
    <Header title="รายชื่อผู้ชนะการประมูล" />
    <Title :item="item" />
    <Swiper :images="images" />
    <div class="mt-4 mb-2 text-center">
      <button class="btn btn-link" @click="showTermsModal = true">
        เงื่อนไขและข้อตกลงการใช้บริการ
      </button>
    </div>
    <DateRange :item="item" />
    <HistoryAuction
      v-if="item.type == 'auction'"
      :histories="item.auction_winner"
      :user="item.user_id"
    />
    <Info :item="item" />
    <transition
      enter-active-class="animated slideInRight"
      leave-active-class="animated slideOutRight"
    >
      <Terms
        v-if="showTermsModal"
        @back="showTermsModal = false"
        :terms="item.term_condition"
      />
    </transition>
  </div>
</template>

<script>
import liff from "@line/liff";
import { mapGetters } from "vuex";

import { endCampaignLogin } from "@/api/http";

import Header from "@/components/Header";
import Title from "@/views/WinnerList/components/Title.vue";
import Swiper from "@/views/WinnerList/components/Swiper.vue";
import DateRange from "@/views/WinnerList/components/DateRange.vue";
import HistoryAuction from "@/views/WinnerList/components/HistoryAuction.vue";
import Info from "@/views/WinnerList/components/Info.vue";
import Terms from "@/views/WinnerList/components/Terms.vue";

export default {
  name: "WinnerList",

  components: {
    Header,
    Title,
    Swiper,
    DateRange,
    HistoryAuction,
    Info,
    Terms,
  },

  data() {
    return {
      item: {},
      profile: {},
      showTermsModal: false,
    };
  },

  computed: {
    ...mapGetters({ lineProfile: "user/lineProfile" }),

    images() {
      const { image1, image2, image3, image4 } = this.item;
      return [image1, image2, image3, image4].filter((i) => i != null);
    },
  },

  async created() {
    await this.fetchLot();
  },

  methods: {
    async fetchLot() {
      try {
        const param = this.$route.query.param;
        const lineToken = liff.getAccessToken();
        const { data } = await endCampaignLogin({
          param,
          line_token: lineToken,
        });
        this.item = data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

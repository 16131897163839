export default {
  namespaced: true,

  state: {
    lot: null,
  },

  getters: {
    lotData(state) {
      return state.lot;
    },
  },

  actions: {},

  mutations: {
    SET_LOT_DATA(state, payload) {
      state.lot = payload;
    },
  },
};

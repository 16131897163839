export default {
  namespaced: true,

  state: {
    loading: false,
  },

  getters: {
    isLoading: (state) => {
      return state.loading;
    },
  },

  actions: {
    async setLoading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
};

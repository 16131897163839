export default {
  methods: {
    isLessThanMinimumPoint() {
      return (
        this.point > 0 &&
        this.point < this.minimumBidPoint + this.item.minimum_increment
      );
    },

    isMoreThanMaximumPoint() {
      return this.point > this.minimumBidPoint + this.item.maximum_points;
    },

    showErrorMessageMinimumPoint() {
      const point = this.minimumBidPoint + this.item.minimum_increment;
      this.errorMessage = `อย่าพลาด!!โอกาสเป็นเจ้าของรางวัลนี้ <br/>
      กรุณาใส่คะแนนที่ต้องการประมูลอย่างน้อย ${point} คะแนน`;
    },

    showErrorMessageEmptyOrZeroPoint() {
      const point = this.minimumBidPoint + this.item.minimum_increment;
      this.errorMessage = `อย่าพลาด!!โอกาสเป็นเจ้าของรางวัลนี้ <br/>
      กรุณาใส่คะแนนที่ต้องการประมูลอย่างน้อย ${point} คะแนน`;
    },

    isEmptyOrZeroPoint() {
      return this.point === 0 || !this.point;
    },
  },
};

<template>
  <p>Redirecting...</p>
</template>

<script>

export default {
  mounted() {
    const query = this.$route.query;

    if (query.next) {
      return this.$router.push(query.next);
    }

    this.$router.replace({ name: "Home", query: query });
  },
};
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card card-body p-2 px-3 mt-3">
          <p class="my-0">หมายเลขการประมูล: {{ item.lot_id }}</p>
          <p class="h3">จำนวน: {{ item.no_prize | numberWithCommas }} รางวัล</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>
<style lang="scss" scoped>
.card {
  box-shadow: none;
  border: 1px solid #e3e3e3;
}
</style>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12"><hr /></div>
      <div class="col-12">
        <p class="title">ข้อมูลของรางวัล</p>
      </div>
      <div class="col-12 py-2">
        <p class="h4 my-2">{{ item.name }}</p>
        <p v-html="item.description"></p>
      </div>
      <div class="col-12 mb-3"><hr /></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>
<style lang="scss" scoped>
@import "@/styles/_color.scss";
.title {
  font-weight: 500;
  margin-bottom: 0px;
  color: $primary-color;
}

.card {
  box-shadow: none;
  border: 1px solid #e3e3e3;
}
</style>
